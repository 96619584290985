// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
  {
    id: 1,
    title: "Project Parking Management",
    category: "IoT",
    img: require("@/assets/images/project-gate.png"),
  },
  {
    id: 2,
    title: "Project Money Exchange",
    category: "Web Application",
    img: require("@/assets/images/project-money-2.png"),
  },
  {
    id: 3,
    title: "Project Student Record",
    category: "Web Application",
    img: require("@/assets/images/project-induk-2.png"),
  },
  {
    id: 4,
    title: "Project School Management",
    category: "Web Application",
    img: require("@/assets/images/project-td-2.png"),
  },
  {
    id: 5,
    title: "Project Teacher Assessment",
    category: "Web Application",
    img: require("@/assets/images/project-pkg-2.png"),
  },
  {
    id: 6,
    title: "Project Student Report",
    category: "Web Application",
    img: require("@/assets/images/project-rapor.png"),
  },
  {
    id: 7,
    title: "Project Student Exam",
    category: "Web Application",
    img: require("@/assets/images/project-exam-2.png"),
  },
  {
    id: 8,
    title: "Project Mobile Exam",
    category: "Mobile Application",
    img: require("@/assets/images/project-mobile-1.png"),
  },
];

export default projects;

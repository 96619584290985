export const socialLinks = [
  {
    id: 1,
    name: "GitHub",
    icon: "github",
    url: "https://github.com/nopiibagusputra",
  },
  {
    id: 4,
    name: "LinkedIn",
    icon: "linkedin",
    url: "https://www.linkedin.com/in/m-nofi-oke-i-bagus-putra",
  },
];
